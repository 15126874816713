<template>
  <div>
    <v-tooltip top v-if="item && item.name" open-delay="500">
      <template v-slot:activator="{ on }">
        <v-chip
          small
          class="primaryText--text"
          outlined
          v-on="on"
          style="border: none; font-size: 11px"
        >
        <v-icon
                              class="mr-2"
                              small
                              left
                              v-if="clientStatus"
                              :color="getClientStatusColor(clientStatus)"
                              >fiber_manual_record</v-icon
                            >
          <v-avatar
            size="20"
            :color="item && item.logo ? 'white' : 'secondary'"
            left
          >
            <v-img style="" v-if="item.logo" :src="item.logo" contain></v-img>
            <h3 v-else style="color: white">
              {{ item.name.charAt(0) }}
            </h3>
          </v-avatar>
          <span v-if="item.alias">{{ item.alias }}</span>
          <span v-else-if="item.friendlyName">{{ item.friendlyName }}</span>
          <span v-else>{{ item.name }}</span>
        </v-chip>
      </template>
      <span style="font-size: 12px">{{ item.name }}</span>
      <span v-if="clientStatus" class="ml-1" style="font-size: 12px"> ({{ $Format.capitalizeFirstLetter(clientStatus) }})</span>

    </v-tooltip>
    <h4 v-else-if="group">
        {{ group }}
    </h4>
  </div>
</template>
<script>
export default {
  data: () => ({
    item: null,
    group: null,
    clientStatus: null
  }),
  mounted() {
    if(this.params.organisation){
        this.item = this.params.organisation
        this.clientStatus = this.params.clientStatus
    } else {
      this.item = {
        name: this.params.name,
        alias: this.params.alias,
        friendlyName: this.params.friendlyName,
        logo: this.params.logo
    };
    this.clientStatus = this.params.clientStatus
    }
    if(this.params.node && this.params.node.group && this.params.node.key){
        this.group = this.params.value
    }
  },
  methods: {
    getClientStatusColor(status) {
      switch (status) {
        case "GOOD STANDING":
          return "green";
        case "INACTIVE":
          return "red";
        case "ON HOLD":
          return "orange";
        case "COD":
          return "blue";
          case "Unknown":
          return "grey";
      }
    },
  }
};
</script>