import dateFormat from 'dateformat';
import * as moment from "moment";
const countries = require("../utils/countries.json");

export default {

    getCountryDetail({countryCode, iso2, iso3}) {
        let country = null
        if(countryCode) {
        country = countries.find((c) => c.code === countryCode);
        } else if(iso2) {
        country = countries.find((c) => c.iso2 === iso2);
        } else if(iso3) {
        country = countries.find((c) => c.iso3 === iso3);
        }
        return country;
    },
    calculateDays(start, end) {
        start = moment(start);
        end = moment(end);
        let difference = end.diff(start, "days");
        let count = difference;
        return count + 1;
      },

    removePtyLtd(name) {
        const regex = /\s*\([^)]*\)\s*|\s*\(pty\s*ltd\)\s*|\s*pty\s*ltd\s*|\s*limited\s*|\s*ltd\s*/gi;
        let final = name.replace(regex, '').trim();
        if (final.includes('()')) {
            final.replace('()', '');
        }
        return final;
    },

   avatarAbbreviation(fullname) {
      let abbr = fullname.split(/\s/).reduce((response, word) => (response += word.slice(0, 1).toUpperCase()), "");
      if (abbr.length > 0) {
          abbr = abbr.substring(0, 1);
      }
      return abbr;
  },

    capitalizeFirstLetter(string) {
        return string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : null;
    },
    calculatePercentage(start, end) {
        let result = parseInt(start) / parseInt(end);
        return Math.round(result * 100);
      },

    milestoneTypeFormat(type) {
      let formattedType = null;
      switch (type) {
        case 'EMPTY_PICKUP' :
          formattedType = 'Empty Pickup'
          break;
        case 'LOADING_POINT_1' :
          formattedType = 'Loading Point 1'
          break;
        case 'LOADING_POINT_2' :
          formattedType = 'Loading Point 2'
          break;
        case 'GASSING' :
          formattedType = 'Gassing'
          break;
        case 'WEIGH_BRIDGE' :
          formattedType = 'Weigh Bridge'
          break;
        case 'FULL_DROP_OFF' :
          formattedType = 'Port Holding'
          break;
        case 'DETOUR' :
          formattedType = 'Detour'
          break;
        case 'FULL_PICKUP' :
          formattedType = 'Full Pickup'
          break;
        case 'DELIVERY_POINT_1' :
          formattedType = 'Delivery Point 1'
          break;
        case 'DELIVERY_POINT_2' :
          formattedType = 'Delivery Point 2'
          break;
        case 'DELIVERY_POINT_3' :
          formattedType = 'Delivery Point 3'
          break;
        case 'EMPTY_DROP_OFF' :
          formattedType = 'Empty Drop Off'
          break;
        default:
          formattedType = type;
          break;
      }
      return formattedType;
    },

    formatDate(date) {
        let isoFormat, fullDate, dayMonthYear, dateTime, dayOfWeek, shorterDate, time, dayFullDate, dateTimeDefault, dateTimeAlt, dayMonCommaYear, shortDate;
        if (date) {
          isoFormat = dateFormat(new Date(date), "yyyy-mm-dd");
          dayMonthYear = dateFormat(new Date(date), "dd-mm-yyyy");
          dayFullDate = dateFormat(new Date(date), "ddd, mmmm dS, yyyy");
          fullDate = dateFormat(new Date(date), "dS mmmm, yyyy");
          dateTimeDefault = dateFormat(new Date(date), "dd-mm-yyyy HH:MM");
          dateTime = dateFormat(new Date(date), "dd-mm-yyyy @ HH:MM");
          dateTimeAlt = dateFormat(new Date(date), "yyyy-mm-dd @ HH:MM");
          dayOfWeek = dateFormat(new Date(date), "ddd");
          shorterDate = dateFormat(new Date(date), "mmm dS, yyyy");
          time = dateFormat(new Date(date), "HH:MM");
          dayMonCommaYear = dateFormat(new Date(date), "dd mmm, yyyy");
          shortDate = dateFormat(new Date(date), "dd mmm");
        }
        return {
          default: isoFormat,
            isoFormat,
            dayMonthYear,
            fullDate,
            dateTime,
            dayOfWeek,
            shorterDate,
            time,
            dateTimeDefault,
            dayFullDate,
            dateTimeAlt,
            dayMonCommaYear,
            shortDate
        };
      },

      formatFileSize(bytes, si = true, dp = 1) {
        const thresh = si ? 1000 : 1024;
        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }
        const units = si
            ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10 ** dp;
        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
        return bytes.toFixed(dp) + ' ' + units[u];
      },

      getExtension(type, withDot) {
        try {
            // this function returns the extension for the file type based on the mime type using case switch
            let extension = ''
            switch (type) {
                case 'application/pdf':
                    extension = 'pdf'
                    break;
                case 'application/msword':
                    extension = 'doc'
                    break;
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                    extension = 'docx'
                    break;
                
                case 'application/vnd.ms-excel':
                    extension = 'xls'
                    break;
                case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                    extension = 'xlsx'
                    break;
                case 'application/vnd.ms-powerpoint':
                    extension = 'ppt'
                    break;
                case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                    extension = 'pptx'
                    break;
                case 'application/zip':
                    extension = 'zip'
                    break;
                case 'application/x-rar-compressed':
                    extension = 'rar'
                    break;
                case 'application/x-7z-compressed':
                    extension = '7z'
                    break;
                case 'application/x-tar':
                    extension = 'tar'
                    break;
                case 'text/plain':
                    extension = 'txt'
                    break;
                case 'text/markdown':
                    extension = 'md'
                    break;
                case 'image/jpeg':
                    extension = 'jpg'
                    break;
                case 'image/bmp':
                    extension = 'bmp'
                    break;
                case 'image/png':
                    extension = 'png'
                    break;
                case 'image/gif':
                    extension = 'gif'
                    break;
                case 'image/webp':
                    extension = 'webp'
                    break;
                case 'video/mp4':
                    extension = 'mp4'
                    break;
                case 'audio/mpeg':
                    extension = 'mp3'
                    break;
                case 'audio/ogg':
                    extension = 'ogg'
                    break;
                case 'audio/wav':
                    extension = 'wav'
                    break;
                case 'audio/webm':
                    extension = 'webm'
                    break;
                case 'audio/3gpp':
                    extension = '3gp'
                    break;
                case 'audio/3gpp2':
                    extension = '3g2'
                    break;
                case 'audio/aac':
                    extension = 'aac'
                    break;
                case 'audio/amr':
                    extension = 'amr'
                    break;
                default:
                    break;
            }
            if(extension === '') {
                return 'unknown'
            }
            if (withDot) {
                return '.' + extension
            }
            return extension
        } catch(e) {
            console.log(e)
            return e.message
        }
    },
    getStatusDetail(status) {
        let color = null
        let icon = null
        
        switch (status.toLowerCase()) {
          case "action required": 
              color = "orange";
              icon = "warning";
              break;
          case "open":
            color = "grey";
              break;
          case "not required":
            color = "grey";
            icon = "close";
              break;
          case "not started":
            color = "grey";
            icon = "schedule";
              break;
          case "cancelled":
            color = "grey";
            icon = "cancel";
              break;
          case "approved":
            color = "green";
              break;
          case "complete": 
              color = "green";
              icon = "task_alt";
              break;
          case "delivered":
            color = "green";
            icon = "task_alt";
              break;
          case "in progress":
            color = "indigo";
            icon = "sync";
              break;
          case "created":
            color = "blue";
            icon = "mark_email_read";
              break;
          case "submitted":
            color = "blue";
            icon = "mark_email_read";
              break;
          case "resubmitted":
            color = "cyan";
            icon = "mark_email_read";
              break;
          case "draft":
            color = "orange";
            icon = "edit_note";
              break;
          case "file generated":
            color = "orange";
            icon = "task";
              break;
          case "pending":
            color = "orange";
            icon = "pending";
              break;
          case status.includes("pending"):
            color = "orange";
            icon = "pending";
              break;
          case "draft approved":
            color = "purple";
            icon = "fact_check";
              break;
          case "revision required":
            color = "red";
              break;
          case "rejected":
            color = "red";
            icon = "cancel";
              break;
          case "query":
            color = "purple";
              break;
          case "failed":
            color = "red";
            icon = "cancel";
              break;
          case "new":
            color = "amber";
            icon = "star";
              break;
          case "error":
            color = "red";
            icon = "cancel";
              break;
          case "not created":
            color = "red";
            icon = "error";
              break;
          case "in transit":
            color = "blue";
            icon = "local_shipping";
              break;
          case "validation failed":
            color = "red";
            icon = "error";
              break;
          case "inspection failed":
            color = "red";
            icon = "error";
              break;
          case "inspection approved":
            color = "success";
            icon = "fact_check";
              break;
          default:
            color = null;
            icon = null;
        }
        if(!color && !icon) {
            if(status.toLowerCase().includes("pending")) {
                color = "orange";
                icon = "pending";
            } else if(status.toLowerCase().includes("approved")) {
              color = "green";
              icon = "fact_check";
            } else if(status.toLowerCase().includes("failed")) {
              color = "red";
              icon = "error";
            } else if(status.toLowerCase().includes("revoked")) {
              color = "red";
              icon = "error";
            } else {
                color = "grey";
                icon = "info";
            }
        }
        return { color: color, icon: icon};
    },

    getServicesIcons(service) {
      let obj = {
        icon: 'help',
        color: 'grey',
      }
      switch(service) {
        case 'Genset':
          obj.icon = 'bolt';
          obj.color = '#D6D46D';
          break;
        case 'Fumigation':
          obj.icon = 'cleaning_services';
          obj.color = '#F1DBBF';
          break;
        case 'Void Plugs':
          obj.icon = 'power';
          obj.color = '#e05b0d';
          break;
        case 'Blast Freezing':
          obj.icon = 'ac_unit';
          obj.color = '#13a7d4';
          break;
        case 'Monitoring Service':
          obj.icon = 'desktop_windows';
          obj.color = '#99B080';
          break;
        case 'ContainerLiners':
          obj.icon = 'settings_overscan';
          obj.color = '#EEF296';
          break;
        case 'SmartFresh':
          obj.icon = 'smart_screen';
          obj.color = '#FFBFFF';
          break;
        case 'SteriInsurance':
          obj.icon = 'emergency';
          obj.color = '#EEF296';
          break;
        case 'TelemPlus':
          obj.icon = 'router';
          obj.color = '#18e00d';
          break;
        case 'Otflow':
          obj.icon = 'air';
          obj.color = '#6A9C89';
          break;
        case 'Zoono':
          obj.icon = 'sanitizer';
          obj.color = '#5b93c7';
          break;
        case 'Dunnage Bags':
          obj.icon = 'shopping_bag';
          obj.color = '#F1DBBF';
          break;
        case 'Late Arrival':
          obj.icon = 'watch_later';
          obj.color = '#BB2525';
          break;
        case 'Latches':
          obj.icon = 'pivot_table_chart';
          obj.color = '#508D69';
          break;
        case 'Ratches':
          obj.icon = 'settings_input_component';
          obj.color = '#EA906C';
          break;
        case 'Stabling bars':
          obj.icon = 'video_stable';
          obj.color = '#5F6F52';
          break;
        case 'Kraft paper':
          obj.icon = 'note';
          obj.color = '#C5E898';
          break;
        case 'Absorb Bags':
          obj.icon = 'backpack';
          obj.color = '#DCBFFF';
          break;
        default:
          obj.icon = 'help';
          obj.color = 'grey';
          break;
      }
      return obj;
    },

}