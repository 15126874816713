const ContainerValidator = (function () {
    const STR_PAD_LEFT = 'STR_PAD_LEFT';
// :disabled="!checkContainer(container.containerNo)"
    const alphabetNumerical = {
        'A': 10, 'B': 12, 'C': 13, 'D': 14, 'E': 15, 'F': 16, 'G': 17, 'H': 18, 'I': 19,
        'J': 20, 'K': 21, 'L': 23, 'M': 24, 'N': 25, 'O': 26, 'P': 27, 'Q': 28, 'R': 29,
        'S': 30, 'T': 31, 'U': 32, 'V': 34, 'W': 35, 'X': 36, 'Y': 37, 'Z': 38
    };

    const pattern = /^([A-Z]{3})(U|J|Z)(\d{6})(\d)$/;
    const patternWithoutCheckDigit = /^([A-Z]{3})(U|J|Z)(\d{6})$/;

    function ContainerValidator(param) {
        let errorMessages = [];
        let ownerCode = [];
        let productGroupCode;
        let registrationDigit = [];
        let checkDigit;

        function empty(mixed_var) {
            const emptyValues = [undefined, null, false, 0, '', '0'];
            if (emptyValues.includes(mixed_var)) return true;
            if (typeof mixed_var === 'object') {
                for (const key in mixed_var) {
                    return false;
                }
                return true;
            }
            return false;
        }

        function isString(param) {
            return typeof param === 'string';
        }

        function count(array) {
            return array ? array.length : 0;
        }

        function strtoupper(string) {
            return string.toUpperCase();
        }

        function str_len(string) {
            return string.length;
        }

        function str_split(string, split_length = 1) {
            if (string == null || split_length < 1) return false;
            const chunks = [];
            let pos = 0;
            while (pos < string.length) {
                chunks.push(string.slice(pos, pos += split_length));
            }
            return chunks;
        }

        function str_pad(input, pad_length, pad_string, pad_type = 'STR_PAD_RIGHT') {
            const str_pad_repeater = (s, len) => {
                let collect = '';
                while (collect.length < len) {
                    collect += s;
                }
                return collect.substr(0, len);
            };

            input = String(input);
            pad_string = pad_string !== undefined ? pad_string : ' ';
            let pad_to_go = pad_length - input.length;

            if (pad_to_go > 0) {
                if (pad_type === 'STR_PAD_LEFT') {
                    input = str_pad_repeater(pad_string, pad_to_go) + input;
                } else if (pad_type === 'STR_PAD_RIGHT') {
                    input = input + str_pad_repeater(pad_string, pad_to_go);
                } else if (pad_type === 'STR_PAD_BOTH') {
                    const half = str_pad_repeater(pad_string, Math.ceil(pad_to_go / 2));
                    input = half + input + half;
                    input = input.substr(0, pad_length);
                }
            }
            return input;
        }

        function array_merge(...args) {
            const toStr = Object.prototype.toString;
            const isArray = (arg) => toStr.call(arg) === '[object Array]';
            let retArr = true;

            for (const arg of args) {
                if (!isArray(arg)) {
                    retArr = false;
                    break;
                }
            }

            if (retArr) {
                return args.flat();
            }

            const retObj = [];
            for (const arg of args) {
                if (isArray(arg)) {
                    retObj.push(...arg);
                } else {
                    for (const k in arg) {
                        if (Object.hasOwn(arg, k)) {
                            if (parseInt(k, 10) + '' === k) {
                                retObj.push(arg[k]);
                            } else {
                                retObj[k] = arg[k];
                            }
                        }
                    }
                }
            }
            return retObj;
        }

        function preg_match(pattern, string) {
            const regex = new RegExp(pattern);
            return regex.exec(string);
        }

        function buildCheckDigit(matches) {
            if (matches[1]) {
                ownerCode = str_split(matches[1]);
            }
            if (matches[2]) {
                productGroupCode = matches[2];
            }
            if (matches[3]) {
                registrationDigit = str_split(matches[3]);
            }
            if (matches[4]) {
                checkDigit = matches[4];
            }

            const numericalOwnerCode = ownerCode.map(code => alphabetNumerical[code]);
            numericalOwnerCode.push(alphabetNumerical[productGroupCode]);

            const numericalCode = array_merge(numericalOwnerCode, registrationDigit);
            const sumDigit = numericalCode.reduce((sum, value, index) => sum + value * Math.pow(2, index), 0);

            const sumDigitDiff = Math.floor(sumDigit / 11) * 11;
            const calculatedCheckDigit = sumDigit - sumDigitDiff;

            return (calculatedCheckDigit === 10) ? 0 : calculatedCheckDigit;
        }

        function identify(containerNumber, withoutCheckDigit = false) {
            clearErrors();
            const patternToUse = withoutCheckDigit ? patternWithoutCheckDigit : pattern;
            return preg_match(patternToUse, strtoupper(containerNumber));
        }

        function clearErrors() {
            errorMessages = [];
        }

        function validate(containerNumber) {
            let matches = [];

            if (!empty(containerNumber) && isString(containerNumber)) {
                matches = identify(containerNumber);

                if (count(matches) !== 5) {
                    errorMessages.push('The container number is invalid');
                } else {
                    const calculatedCheckDigit = buildCheckDigit(matches);
                    if (checkDigit != calculatedCheckDigit) {
                        errorMessages.push('The check digit does not match');
                        matches = [];
                    }
                }
            } else {
                errorMessages = ['The container number must be a string'];
            }
            return matches;
        }

        function createCheckDigit(containerNumber) {
            let checkDigit = -1;
            if (!empty(containerNumber) && isString(containerNumber)) {
                const matches = identify(containerNumber, true);

                if (count(matches) !== 4 || (matches[4])) {
                    errorMessages.push('Invalid container number');
                } else {
                    checkDigit = buildCheckDigit(matches);
                    if (checkDigit < 0) {
                        errorMessages.push('Invalid container number');
                    }
                }
            } else {
                errorMessages.push('Container number must be a string');
            }
            return checkDigit;
        }

        function generate(ownerCode, productGroupCode, from = 0, to = 999999) {
            const alphabetCode = strtoupper(ownerCode + productGroupCode);
            const containersNo = [];

            if (isString(alphabetCode) && str_len(ownerCode) === 3 && str_len(productGroupCode) === 1) {
                if (from >= 0 && to < 1000000 && (to - from) > 0) {
                    for (let i = from; i <= to; i++) {
                        const currentContainerNo = alphabetCode + str_pad(i, 6, '0', STR_PAD_LEFT);
                        const currentContainerCheckDigit = createCheckDigit(currentContainerNo);

                        if (currentContainerCheckDigit < 0) {
                            errorMessages.push('Error generating container number at number ' + i);
                            return containersNo;
                        }

                        containersNo[i] = currentContainerNo + currentContainerCheckDigit;
                    }
                } else {
                    errorMessages.push('Invalid number to generate, minimal is 0 and maximal is 999999');
                }
            } else {
                errorMessages.push('Invalid owner code or product group code');
            }

            return containersNo;
        }

        function getErrorMessages() {
            return errorMessages;
        }

        function getOwnerCode() {
            if (empty(ownerCode)) {
                errorMessages.push('You must call validate or isValid first');
            }
            return ownerCode;
        }

        function getProductGroupCode() {
            if (empty(productGroupCode)) {
                errorMessages.push('You must call validate or isValid first');
            }
            return productGroupCode;
        }

        function getRegistrationDigit() {
            if (empty(registrationDigit)) {
                errorMessages.push('You must call validate or isValid first');
            }
            return registrationDigit;
        }

        function getCheckDigit() {
            if (empty(checkDigit)) {
                errorMessages.push('You must call validate or isValid first');
            }
            return checkDigit;
        }

        function isValid(containerNumber) {
            validate(containerNumber);
            return empty(errorMessages);
        }

        return {
            isValid,
            getErrorMessages,
            getOwnerCode,
            getProductGroupCode,
            getRegistrationDigit,
            getCheckDigit,
            generate
        };
    }

    return ContainerValidator;
})();

module.exports = ContainerValidator;
