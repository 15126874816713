<template>
  <div class="">
    <v-app-bar
      dense
      app
      :dark="darkMode"
      v-if="$store.state.user && $vuetify.breakpoint.smAndDown"
    >
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.smAndDown"
        @click="(drawer = true), (mini = false)"
      >
      </v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-badge
        :key="notificationUpdateKey"
        :content="notifications.total"
        :value="notifications.total"
        color="primary"
        overlap
        class="count mr-6"
      >
        <v-icon
          @click="
            $router.push({ path: '/notification' }), (notifications.total = 0)
          "
          >notifications</v-icon
        >
      </v-badge>
      <v-chip v-if="!$vuetify.breakpoint.mobile">Week: {{ week }}</v-chip>
      <span
        v-if="!$vuetify.breakpoint.mobile"
        class="text-no-wrap mr-2"
        style="margin-left: 15px; font-size: 14px"
        >{{ firstDayOfWeek() }} - {{ lastDayOfWeek() }}</span
      >
      <v-menu
        v-if="$store.state.user"
        bottom
        min-width="200px"
        class="mt-0 pt-0"
        rounded
        offset-y
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <v-avatar
            style="cursor: pointer"
            color="secondary"
            size="36"
            v-on="on"
            class="mt-0 pt-0"
          >
            <span
              v-if="$store.state.user && !$store.state.user.avatar"
              class="text-h5"
              >{{
                $Format.avatarAbbreviation(
                  $store.state.user.firstname + " " + $store.state.user.surname
                )
              }}</span
            >
            <img
              v-else
              :src="$store.state.user.avatar"
              referrerpolicy="no-referrer"
            />
          </v-avatar>
        </template>
        <v-card>
          <v-list>
            <v-list-item @click="editUser()">
              <v-list-item-action>
                <v-avatar color="secondary">
                  <span v-if="!$store.state.user.avatar" class="text-h5">{{
                    $Format.avatarAbbreviation(
                      $store.state.user.firstname +
                        " " +
                        $store.state.user.surname
                    )
                  }}</span>
                  <img
                    v-else
                    :src="$store.state.user.avatar"
                    referrerpolicy="no-referrer"
                  />
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $store.state.user.firstname }}
                  {{ $store.state.user.surname }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $store.state.user.emailAddress }}
                </v-list-item-subtitle>
                <v-list-item-subtitle> Edit Profile </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item style="height: 35px; font-size: 12px">
              <v-list-item-action>
                <v-icon>light_mode</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 16px">
                  Dark Mode
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch
                  small
                  color="primary"
                  @change="setMode"
                  v-model="darkMode"
                ></v-switch>
              </v-list-item-action>
            </v-list-item> -->
            <!-- <v-list-item
              style="height: 35px; font-size: 12px"
              @click="editNotificationSettings"
            >
              <v-list-item-action>
                <v-icon>tune</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 16px">
                  Notification Settings
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
            <v-list-item>
              <v-list-item-content class="mx-auto text-center">
                <v-btn depressed rounded text @click="signOut()">
                  Sign Out <v-icon small class="ml-2">logout</v-icon>
                </v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      :key="updateKey"
      v-model="drawer"
      app
      floating
      mini-variant
      permanent
      class="text-xs-left"
      style="
        background: rgba(0, 0, 0, 0.2);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(16px);
        -webkit-backdrop-filter: blur(16px);
        height: calc(100vh - 18px);
        max-height: calc(100vh - 18px) !important;
      "
    >
      <!-- 
      style="background-color: var(--v-toolbar-base)"
    
    -->
      <v-list dense>
        <v-list-item class="px-2 mb-0 pb-0" style="height: 50px">
          <v-list-item-action>
            <v-avatar tile size="36">
              <v-img
                contain
                :src="
                  $vuetify.theme.dark
                    ? tenantBrand.logoUrl.navBarLight
                    : tenantBrand.logoUrl.navBarDark
                "
              />
            </v-avatar>
          </v-list-item-action>
          <v-list-item-content class="ml-0 pl-0">
            <v-list-item-title v-if="tenantBrand.showNavLabel">
              <span
                v-for="(char, i) in tenantBrand.label"
                :key="i"
                class="smallLogo"
                >{{ char }}</span
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
      </v-list>

      <v-list
        nav
        shaped
        dense
        subheader
        style="height: auto; left: 0; z-index: 0" :key="organisationKey"
      >
        <!-- <v-list-item-group> -->
          <v-tooltip right>
            <template v-slot:activator="{ on }">
        <v-list-item v-on="on"
          class="ml-0 pl-0 mt-0 pt-0"
          @click="clickChangeOrganisation()"
          v-if="currentOrganisation"
          style="height: 35px; font-size: 12px"
        >
          <v-list-item-avatar
            v-if="currentOrganisation.icon"
            class="text-center"
            style="background: white"
          >
            <v-img contain :src="currentOrganisation.icon"></v-img>
          </v-list-item-avatar>
          <v-list-item-avatar
            v-else-if="currentOrganisation.logo"
            class="text-center"
            style="background: white"
          >
            <v-img contain :src="currentOrganisation.logo"></v-img>
          </v-list-item-avatar>
          <v-list-item-action v-else class="pl-1">
            <v-avatar color="secondary" size="36">
              <span class="text-h5">{{
                $Format.avatarAbbreviation(currentOrganisation.name)
              }}</span>
            </v-avatar>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="">
              <span v-if="currentOrganisation.alias">
                {{ currentOrganisation.alias }}
              </span>
              <span v-else>
                {{ currentOrganisation.name }}
              </span>
            </v-list-item-title>
            <v-list-item-subtitle
              style="font-size: 10px; color: grey"
              v-if="organisations.length > 0"
            >
              Change Organisation
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon color="grey">swap_horiz</v-icon>
          </v-list-item-action>
        </v-list-item>
        </template>
        <span style="font-size: 12px" v-if="currentOrganisation && currentOrganisation.alias">
               Current: {{ currentOrganisation.alias }}
              </span>
              <span style="font-size: 12px" v-else-if="currentOrganisation">
                Current: {{ currentOrganisation.name }}
              </span>
              <br/>
        <span style="font-size: 12px">Change Organisation</span>
        </v-tooltip>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-list-item
              @click="globalSearch = true"
              style="height: 10px"
              v-on="on"
            >
              <v-list-item-action>
                <v-icon color="grey">search</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 14px; color: grey"
                  >Search</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </template>
          <span style="font-size: 12px">Search</span>
        </v-tooltip>
        <v-list-item v-if="loadingNavigation && navbarList.length == 0">
          <v-list-item-content class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
              size="20"
            ></v-progress-circular>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list dense nav> -->
        <div v-for="(item, index) in navbarList" :key="index">
          <v-tooltip right v-if="!item.group">
            <template v-slot:activator="{ on }">
              <v-list-item v-on="on" :to="item.route" link class="menu-item" @click="setActive(item, index)">
                <v-list-item-action
                  :key="navbarKey"
                  v-if="!item.image"
                  class="mr-0 ml-1"
                >
                  <v-icon :color="item.active ? 'primary' : 'grey'" small>{{
                    item.icon
                  }}</v-icon>
                </v-list-item-action>
                <!-- <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon> -->
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <span style="font-size: 12px">{{ item.name }}</span>
          </v-tooltip>
          <v-menu
            v-else
            offset-x
            nudge-right="9"
            transition="slide-x-transition"
            open-on-hover
            close-delay="200"
            open-delay="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item link v-bind="attrs" v-on="on" class="menu-item">
                <v-list-item-action
                  :key="navbarKey"
                  v-if="!item.image"
                  class="mr-0 ml-1"
                >
                  <v-icon :color="item.active ? 'primary' : 'grey'" small>{{
                    item.icon
                  }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list
              dense
              subheader
              style="
                background-color: var(--v-greyRaised-base); /* Fallback */
                background: var(--v-greyRaised-base); /* Blue with transparency */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
              "
            >
              <v-subheader style="font-size: 13px">{{ item.name }}</v-subheader>
              <v-divider></v-divider>
              <v-list-item
                v-for="(subItem, subIndex) in item.items"
                :key="subItem.name"
                :to="subItem.route"
                link
                 @click="setSubActive(item, subItem, index, subIndex)"
              >
              <v-list-item-action class="mr-1">
                <v-icon x-small color="grey">
                  fiber_manual_record
                </v-icon>
              </v-list-item-action>
                <v-list-item-title style="font-size: 11px">{{
                  subItem.name
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <v-divider></v-divider>
        <v-spacer></v-spacer>
        <v-menu
            offset-x
            transition="slide-x-transition"
            open-on-hover
            close-delay="200"
            open-delay="200"
            nudge-right="4"
            v-model="showContactOptions"
          >
          <template v-slot:activator="{ on }">
        <v-list-item
          v-on="on"
          @click="showContactOptions = !showContactOptions"
          class="pl-0 py-0 ml-0 my-0"
          v-if="$store.state.user"
          style="
            background-color: transparent;
            position: absolute;
            bottom: 5px;
            height: 65px;
            z-index: 5;
          "
        >
          <v-list-item-avatar
            v-if="$store.state.user.avatar"
            class="ml-0 pl-0"
            style="left: 0"
          >
            <img
              :src="$store.state.user.avatar"
              referrerpolicy="no-referrer"
              class="ml-0 pl-0"
              contain
              style="left: 0; padding: 0; margin: 0"
            />
          </v-list-item-avatar>
          <v-list-item-action v-else class="pl-0 ml-0">
            <v-avatar color="secondary" size="36" class="pl-0 ml-0">
              <span class="text-h5">{{
                $Format.avatarAbbreviation(
                  $store.state.user.firstname + " " + $store.state.user.surname
                )
              }}</span>
            </v-avatar>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="">
              {{ $store.state.user.firstname }}
              {{ $store.state.user.surname }}
            </v-list-item-title>
            <v-list-item-subtitle class="" style="font-size: 12px">
              {{ $store.state.user.emailAddress }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon
              ><v-icon v-if="!showContactOptions">expand_more</v-icon
              ><v-icon v-else>expand_less</v-icon></v-btn
            >
          </v-list-item-action>
        </v-list-item>
        </template>
        <v-list
            v-if="showContactOptions && $store.state.user"
            dense
              subheader
              style="
                background-color: var(--v-greyRaised-base); /* Fallback */
                background: var(--v-greyRaised-base); /* Blue with transparency */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
              "
          >
          <v-subheader style="font-size: 14px">Account Settings</v-subheader>
          <v-divider></v-divider>
          <v-list-item
          v-if="$store.state.user" @click="editUser()"
          style="
            height: 65px;
          "
        >
          <v-list-item-avatar
            v-if="$store.state.user.avatar"
            class="ml-0 pl-0"
            style="left: 0"
          >
            <img
              :src="$store.state.user.avatar"
              referrerpolicy="no-referrer"
              class="ml-0 pl-0"
              contain
              style="left: 0; padding: 0; margin: 0"
            />
          </v-list-item-avatar>
          <v-list-item-action v-else class="pl-0 ml-0">
            <v-avatar color="secondary" size="36" class="pl-0 ml-0">
              <span class="text-h5">{{
                $Format.avatarAbbreviation(
                  $store.state.user.firstname + " " + $store.state.user.surname
                )
              }}</span>
            </v-avatar>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title  style="font-size: 12px">
              {{ $store.state.user.firstname }}
              {{ $store.state.user.surname }}
            </v-list-item-title>
            <v-list-item-subtitle style="font-size: 11px">
              {{ $store.state.user.emailAddress }}
            </v-list-item-subtitle>
            <v-list-item-subtitle style="font-size: 11px">
              Edit Profile
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
            <v-list-item @click="bugReport">
              <v-list-item-icon class="mr-1">
                <v-icon color="grey" small>bug_report</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="font-size: 12px"> Report a Bug </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item style="height: 35px; opacity: 1" @click="editUser()">
              <v-list-item-icon class="mr-1">
                <v-icon color="grey" small>person</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title  style="font-size: 12px"> Edit Profile </v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
            <!-- <v-list-item
              style="height: 35px"
              @click="editNotificationSettings()"
            >
              <v-list-item-icon>
                <v-icon color="grey">tune</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="">
                  Notification Settings
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
            <v-list-item @click="signOut()">
              <v-list-item-icon  class="mr-1">
                <v-icon color="grey" small>exit_to_app</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="font-size: 11px"> Sign Out </v-list-item-title>
              </v-list-item-content>
            </v-list-item></v-list
          >
        </v-menu>
      </v-list>
      
    </v-navigation-drawer>

    <v-dialog width="400px" v-model="changeOrganisation">
      <v-card>
        <v-card-text class="mt-2 pt-2">
          <v-text-field
          class="px-2 mb-1 pb-0 mt-2"
          style="font-size: 14px"
          :dark="$store.state.darkMode"
          autofocus
          ref="organisationSearch"
          v-model="searchOrganisations"
          hide-details
          dense
          rounded
          prepend-inner-icon="search"
          outlined
          placeholder="Search"
        ></v-text-field>
          <v-list nav shaped dense style="max-height: 500px; overflow-y: auto">
        <v-list-item
          v-for="item in filterOrganisations"
          :key="item.id"
          style="height: 30px"
          @click="(currentOrganisation = item), (changeOrganisation = false)"
        >
          <v-list-item-avatar
            v-if="item.icon"
            class="pl-0 ml-0"
            style="background: white"
          >
            <v-img contain :src="item.icon"></v-img>
          </v-list-item-avatar>
          <v-list-item-avatar
            v-else-if="item.logo"
            class="pl-0 ml-0"
            style="background: white"
          >
            <v-img contain :src="item.logo"></v-img>
          </v-list-item-avatar>
          <v-list-item-action v-else>
            <v-avatar color="secondary" size="36">
              <span class="text-h6">{{
                $Format.avatarAbbreviation(item.name)
              }}</span>
            </v-avatar>
          </v-list-item-action>
          <v-list-item-title style="">
            <span v-if="item.alias">
              {{ item.alias }}
            </span>
            <span v-else>
              {{ item.name }}
            </span></v-list-item-title
          >
        </v-list-item>
        <v-list-item v-if="filterOrganisations.length == 0">
          <v-list-item-content
            class="text-center"
            style="font-size: 14px; color: grey"
          >
            No organisations match your search
          </v-list-item-content>
        </v-list-item>
      </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Profile Dialog -->
    <v-dialog
      v-model="viewProfile"
      persistent
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card :loading="savingUser">
        <v-toolbar flat color="transparent">
          <v-toolbar-title> My Profile </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="saveUser">
            <v-icon>save</v-icon>
          </v-btn>
          <v-btn text @click="handleClose">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container fluid>
          <v-card-text>
            <v-row class="py-4 mb-5" justify="center">
              <v-avatar color="secondary" size="72">
                <span v-if="!contact.avatar" class="text-h5">{{
                  $Format.avatarAbbreviation(
                    contact.firstname + " " + contact.surname
                  )
                }}</span>
                <img
                  v-else
                  :src="contact.avatar"
                  referrerpolicy="no-referrer"
                />
              </v-avatar>
            </v-row>
            <v-row justify="center">
              <v-text-field
                prepend-inner-icon="person"
                label="Name"
                v-model="contact.firstname"
                outlined
                dense
              >
              </v-text-field>
              <v-text-field
                prepend-inner-icon="person"
                label="Surname"
                v-model="contact.surname"
                outlined
                dense
              >
              </v-text-field>
              <v-text-field
                disabled
                prepend-inner-icon="email"
                label="Email"
                v-model="contact.emailAddress"
                outlined
                dense
              >
              </v-text-field>
              <v-text-field
                prepend-inner-icon="phone"
                label="Mobile Number"
                v-model="contact.mobileNumber"
                outlined
                dense
              >
              </v-text-field>
              <v-btn color="primary" text @click="viewProfileSignatures = true">
                View Signatures
              </v-btn>
            </v-row>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- profile signatures -->
    <v-dialog
      v-model="viewProfileSignatures"
      persistent
      width="800px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card :loading="savingProfileSignatures">
        <v-toolbar flat color="transparent">
          <v-toolbar-title> My Signature and Initials </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="saveUserSignatures">
            <v-icon>save</v-icon>
          </v-btn>
          <v-btn text @click="viewProfileSignatures = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="6">
              <v-card class="mx-auto" tile>
                <v-toolbar flat color="transparent">
                  <v-card flat>
                    <v-card-subtitle class="pl-0"
                      >Black Signature</v-card-subtitle
                    >
                  </v-card>
                  <v-spacer></v-spacer>
                  <v-btn text @click="editSignature('Black')">
                    <v-icon small>edit</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-img
                  class="signature"
                  v-if="contact.signature"
                  :src="`data:image/png;base64,${contact.signature}`"
                  height="150px"
                  contain
                >
                </v-img>
              </v-card>
              <v-card class="mx-auto" tile>
                <v-toolbar flat color="transparent">
                  <v-card flat>
                    <v-card-subtitle class="pl-0"
                      >Black Initials</v-card-subtitle
                    >
                  </v-card>
                  <v-spacer></v-spacer>
                  <v-btn text @click="editInitials('Black')">
                    <v-icon small>edit</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-img
                  class="signature"
                  v-if="contact.initials"
                  :src="`data:image/png;base64,${contact.initials}`"
                  height="150px"
                  contain
                >
                </v-img>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="6">
              <v-card class="mx-auto" tile>
                <v-toolbar flat color="transparent">
                  <v-card flat>
                    <v-card-subtitle class="pl-0"
                      >Blue Signature</v-card-subtitle
                    >
                  </v-card>
                  <v-spacer></v-spacer>
                  <v-btn text @click="editSignature('Blue')">
                    <v-icon small>edit</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-img
                  class="signature"
                  v-if="contact.signatureBlue"
                  :src="`data:image/png;base64,${contact.signatureBlue}`"
                  height="150px"
                  contain
                >
                </v-img>
              </v-card>
              <v-card class="mx-auto" tile>
                <v-toolbar flat color="transparent">
                  <v-card flat>
                    <v-card-subtitle class="pl-0"
                      >Blue Initials</v-card-subtitle
                    >
                  </v-card>
                  <v-spacer></v-spacer>
                  <v-btn text @click="editInitials('Blue')">
                    <v-icon small>edit</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-img
                  class="signature"
                  v-if="contact.initialsBlue"
                  :src="`data:image/png;base64,${contact.initialsBlue}`"
                  height="150px"
                  contain
                >
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- signature dialog -->
    <v-dialog
      v-model="signatureDialog"
      persistent
      max-width="500px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar color="transparent">
          <v-card-title>Signature</v-card-title>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on" @click="clearSignature">
                <v-icon>browser_not_supported</v-icon>
              </v-btn>
            </template>
            <span>Clear signature</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                @click="saveSignature(signatureColor)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>save</v-icon>
              </v-btn>
            </template>
            <span>Update and close</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text @click="closeSignature" v-bind="attrs" v-on="on">
                <v-icon>close</v-icon>
              </v-btn>
            </template>
            <span>Close without updating</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-container class="signature-container" fluid>
            <VueSignaturePad
              id="signature"
              width="400px"
              height="200px"
              ref="signaturePad"
              :options="{
                penColor: signatureColor == 'Blue' ? '#0d3a94' : '#000000',
                onBegin: () => {
                  $refs.signaturePad.resizeCanvas();
                },
              }"
            ></VueSignaturePad>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- initials dialog -->
    <v-dialog
      v-model="initialsDialog"
      persistent
      max-width="500px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar color="transparent">
          <v-card-title>Initials</v-card-title>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on" @click="clearInitials">
                <v-icon>browser_not_supported</v-icon>
              </v-btn>
            </template>
            <span>Clear initials</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                @click="saveInitials(initialsColor)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>save</v-icon>
              </v-btn>
            </template>
            <span>Update and close</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text @click="closeInitials" v-bind="attrs" v-on="on">
                <v-icon>close</v-icon>
              </v-btn>
            </template>
            <span>Close without updating</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-container class="signature-container" fluid>
            <VueSignaturePad
              id="signature"
              width="400px"
              height="200px"
              ref="initialsPad"
              :options="{
                penColor: initialsColor == 'Blue' ? '#0d3a94' : '#000000',
                onBegin: () => {
                  $refs.initialsPad.resizeCanvas();
                },
              }"
            />
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Notifications Dialog -->
    <v-dialog
      v-model="notificationSettingsModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <v-icon class="mr-2" color="grey">settings</v-icon>
            Notifications
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="saveNotificationSettings"
            color="secondary"
            :loading="savingNotifications"
            ><v-icon>save</v-icon></v-btn
          >
          <v-btn text @click="notificationSettingsModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-subheader>Communication Methods</v-subheader>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-action>
              <v-icon>email</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title> Email </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch v-model="contact.emailNotification"></v-switch>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon>campaign</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title> Push Notifications </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch v-model="contact.pushNotification"></v-switch>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon>notifications_active</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title> In-App Notifications </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch v-model="contact.inAppNotification"></v-switch>
            </v-list-item-action>
          </v-list-item>
          <v-list
            v-for="(available, index) in availableSettings"
            :key="index"
            dense
          >
            <v-divider></v-divider>
            <v-subheader>{{ available.category }}</v-subheader>
            <v-divider></v-divider>
            <v-list-item
              v-for="(settingItem, itemIndex) in available.events"
              :key="itemIndex"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ settingItem.name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch
                  :value="settingItem.value"
                  v-model="selectedSettings"
                ></v-switch>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text> </v-card
    ></v-dialog>

    <v-dialog
      v-model="globalSearch"
      width="500px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card
        style="top: 80px; position: absolute; width: 500px"
        :loading="loadingGlobalSearch"
      >
        <v-card-text>
          <v-list dense>
            <v-text-field
              class="mt-3"
              outlined
              dense
              prepend-inner-icon="search"
              rounded
              placeholder="Search"
              autofocus
              v-model="search"
              clearable
            ></v-text-field>
            <v-chip-group
              active-class="blue--text"
              v-model="selectedOption"
              column
            >
              <v-chip v-for="option in options" :key="option" small>
                {{ option }}
              </v-chip>
            </v-chip-group>
          </v-list>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item
              v-if="searchResults.length == 0 && !loadingGlobalSearch"
            >
              <v-list-item-content class="text-center">
                <span style="font-size: 12px; color: grey">No results</span>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="(item, index) in searchResults" :key="index">
              <v-list-item-action>
                <v-tooltip top v-if="item.type == 'FILE'">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="teal">description</v-icon>
                  </template>
                  <span>File</span>
                </v-tooltip>
                <v-tooltip top v-else-if="item.type == 'CONTAINER'">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="orange">widgets</v-icon>
                  </template>
                  <span>Container</span>
                </v-tooltip>
                <v-tooltip top v-else-if="item.type == 'BOOKING'">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="blue-grey"
                      >confirmation_number</v-icon
                    >
                  </template>
                  <span>Booking</span>
                </v-tooltip>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="item.subtitle"
                  style="font-size: 12px"
                >
                  {{ item.subtitle }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  v-if="item.fileNumber"
                  style="font-size: 12px"
                >
                  File Number: {{ item.fileNumber }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  v-if="item.transportCoordinator"
                  style="font-size: 12px"
                >
                  Transport Coordinator: {{ item.transportCoordinator }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  v-if="item.createdAt"
                  style="font-size: 12px"
                >
                  {{ formatDate(item.createdAt) }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-container>
                  <v-row justify="center" v-if="item.type == 'FILE'">
                    <v-tooltip top v-if="item.isOwner">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          icon
                          v-on="on"
                          class="mx-1"
                          @click="viewFile(item.id)"
                        >
                          <v-icon small color="grey">visibility</v-icon>
                        </v-btn>
                      </template>
                      <span>View File</span>
                    </v-tooltip>
                    <v-tooltip top v-if="item.isOwner && item.shipmentReference">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          icon
                          v-on="on"
                          class="mx-1"
                          @click="viewOwnerShipment(item.shipmentReference)"
                        >
                          <v-icon small color="grey">launch</v-icon>
                        </v-btn>
                      </template>
                      <span>View Shipment</span>
                    </v-tooltip>
                    <v-tooltip top v-else-if="item.shipmentReference">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          icon
                          v-on="on"
                          class="mx-1"
                          @click="viewShipment(item.shipmentReference)"
                        >
                          <v-icon small color="grey">launch</v-icon>
                        </v-btn>
                      </template>
                      <span>View Shipment</span>
                    </v-tooltip>

                    <!-- <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn small icon v-on="on" class="mx-1" @click="getConsigneeProfileData(item.id)">
                <v-icon small color="grey">people</v-icon>
              </v-btn>
            </template>
            <span>View Profile</span>
          </v-tooltip> -->
                  </v-row>
                  <v-row justify="center" v-else-if="item.type == 'CONTAINER'">
                    <!-- <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn small icon v-on="on" class="mx-1" @click="viewContainer(item.id)">
                <v-icon small color="grey">confirmation_number</v-icon>
              </v-btn>
            </template>
            <span>View Details</span>
          </v-tooltip> -->
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          icon
                          v-on="on"
                          class="mx-1"
                          @click="viewContainer(item.id)"
                        >
                          <v-icon small color="grey">info</v-icon>
                        </v-btn>
                      </template>
                      <span>View Details</span>
                    </v-tooltip>
                    <!-- <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn small icon v-on="on" class="mx-1" @click="viewContainerHistory(item)">
                <v-icon small color="grey">update</v-icon>
              </v-btn>
            </template>
            <span>View History</span>
          </v-tooltip> -->
                    <v-tooltip top v-if="item.isOwner">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          icon
                          v-on="on"
                          class="mx-1"
                          @click="viewBooking(item.bookingReference)"
                        >
                          <v-icon small color="grey"
                            >confirmation_number</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>View Booking</span>
                    </v-tooltip>
                    <v-tooltip top v-else>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          icon
                          v-on="on"
                          class="mx-1"
                          @click="viewBookingDetail(item.bookingReference)"
                        >
                          <v-icon small color="grey">launch</v-icon>
                        </v-btn>
                      </template>
                      <span>View Booking</span>
                    </v-tooltip>
                    <!-- <v-tooltip top v-else>
            <template v-slot:activator="{ on }">
              <v-btn small icon v-on="on" class="mx-1" @click="viewBooking(item.bookingReference)">
                <v-icon small color="grey">confirmation_number</v-icon>
              </v-btn>
            </template>
            <span>View Booking</span>
          </v-tooltip> -->
                    <v-tooltip top v-if="item.isOwner && item.shipmentReference">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          icon
                          v-on="on"
                          class="mx-1"
                          @click="viewOwnerShipment(item.shipmentReference)"
                        >
                          <v-icon small color="grey">launch</v-icon>
                        </v-btn>
                      </template>
                      <span>View Shipment</span>
                    </v-tooltip>
                    <v-tooltip top v-else-if="item.shipmentReference">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          icon
                          v-on="on"
                          class="mx-1"
                          @click="viewShipment(item.shipmentReference)"
                        >
                          <v-icon small color="grey">launch</v-icon>
                        </v-btn>
                      </template>
                      <span>View Shipment</span>
                    </v-tooltip>
                    <v-tooltip top v-if="item && item.isOwner">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          icon
                          v-on="on"
                          class="mx-1"
                          @click="getIntermodalData(item)"
                        >
                          <v-icon small color="grey">local_shipping</v-icon>
                        </v-btn>
                      </template>
                      <span>View Transport</span>
                    </v-tooltip>
                  </v-row>
                  <v-row justify="center" v-else-if="item.type == 'BOOKING'">
                    <v-tooltip top v-if="item.isOwner">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          icon
                          v-on="on"
                          class="mx-1"
                          @click="viewBooking(item.reference)"
                        >
                          <v-icon small color="grey">launch</v-icon>
                        </v-btn>
                      </template>
                      <span>View Booking</span>
                    </v-tooltip>
                    <v-tooltip top v-else>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          icon
                          v-on="on"
                          class="mx-1"
                          @click="viewBookingDetail(item.reference)"
                        >
                          <v-icon small color="grey">launch</v-icon>
                        </v-btn>
                      </template>
                      <span>View Booking</span>
                    </v-tooltip>
                  </v-row>
                </v-container>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="fileModal"
      width="90vw"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <ViewFile
        :key="previewKey"
        v-if="selectedFileId"
        :fileId="selectedFileId"
        @close="fileModal = false"
      />
    </v-dialog>

    <v-dialog v-model="containerModal" fullscreen persistent>
      <ContainerSummary
        :containerItem="container"
        @close="containerModal = false"
      />
    </v-dialog>
    <v-dialog
      v-model="containerHistoryModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <ContainerMovementHistory
        :container="container"
        :containerId="container.id"
        @close="containerHistoryModal = false"
      />
    </v-dialog>
    <v-dialog v-model="viewIntermodal" width="94vw" persistent>
      <ViewContainerIntermodal
        v-if="chosenContainer && chosenContainer.container"
        :booking="chosenContainer.booking"
        :container="chosenContainer.container"
        :modalOpen="viewIntermodal"
        @close="viewIntermodal = !viewIntermodal"
      />
    </v-dialog>

    <v-dialog v-model="viewBookingInformation" width="90vw">
      <BookingInformation
        :bookingId="selectedBooking.id"
        :bookingInfo="selectedBooking"
        :systemReference="selectedBooking.systemReference"
        @close="viewBookingInformation = false"
      />
    </v-dialog>
    <v-dialog
      v-model="viewBugReport"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card height="100vh">
        <v-btn
          @click="viewBugReport = false"
          fab
          color="redPop"
          style="position: absolute; top: 16px; right: 16px; z-index: 9999"
        >
          <v-icon color="white">close</v-icon></v-btn
        >
        <iframe
          class="clickup-embed clickup-dynamic-height"
          src="https://forms.clickup.com/9015642520/f/8cnzjcr-1975/1V53ZD07W0QR0RMD8X"
          onwheel=""
          width="100%"
          height="100%"
          style="background: transparent; border: 1px solid #ccc"
        ></iframe>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BookingInformation from "../ShipmentOverview/Components/BookingInformation.vue";

import ContainerSummary from "../SystemComponents/ContainerSummary.vue";
import ContainerMovementHistory from "../SystemComponents/ContainerMovementHistory.vue";
import dateFormat from "dateformat";
import ViewFile from "../SystemComponents/PreviewFileModal.vue";
import ViewContainerIntermodal from "../Bookings/Dialogs/ViewContainerIntermodal.vue";
import { resetRouter } from "../../router";
// import ManageProfile from '../Customer/ConsigneeProfile/ManageProfile.vue'
export default {
  components: {
    BookingInformation,
    ContainerMovementHistory,
    ContainerSummary,
    ViewFile,
    ViewContainerIntermodal,
    // ManageProfile
  },
  data() {
    return {
      productKey: 7000,
      consigneeKey: 6000,
      auditLog: [],
      viewConsigneeProfile: false,
      viewIntermodal: false,
      activeList: null,
      activeSubroute: null,
      activeChild: null,
      activeParent: 0,
      availableSettings: [
        // {
        //   header: 'Tasks',
        //   items: [
        //     {name: 'Task Created', value: 'task_created'},
        //     {name: 'Task Comment', value: 'task_comment'},
        //     {name: 'Status Update', value: 'task_status_update'},
        //     {name: 'Task Attachment', value: 'task_attachment'},
        //     {name: 'Task Completed', value: 'task_complete'}
        //   ]
        // }
      ],
      containerModal: false,
      containerHistoryModal: false,
      container: {},
      viewProfileSignatures: false,
      signatureDialog: false,
      initialsDialog: false,
      savingProfileSignatures: false,
      signatureColor: "Black",
      initialsColor: "Black",
      contact: {},
      changeOrganisation: false,
      currentOrganisation: null,
      currentOrganisationKey: 0,
      darkMode: true,
      drawer: false,
      fileModal: false,
      fixNavBar: false,
      globalSearch: false,
      globalQuery: undefined,
      globalSearchTimeout: undefined,
      loadingNavigation: false,
      lastActive: null,
      loadingGlobalSearch: false,
      navbarKey: 0,
      notificationSettingsModal: false,
      notificationSettings: [],
      notifications: {
        total: 0,
        data: [],
      },
      notificationUpdateKey: 1000,
      options: ["All", "Bookings", "Containers", "Files"],
      organisations: [],
      organisationKey: 0,
      previewKey: 1100,
      editContact: false,
      isCollapse: true,
      navbarList: [],
      mini: true,
      savingNotifications: false,
      savingUser: false,
      search: null,
      searchOrganisations: "",
      viewBugReport: false,
      searchResults: [],
      selectedFileId: null,
      selectedSettings: [],
      selectedOption: 0,
      showContactOptions: false,
      updateKey: 0,
      viewProfile: false,
      chosenContainer: {},
      contacts: [],
      related: [],
      itemLoading: false,
      selectedCustomer: null,
      disabledFields: [],
      selectedBooking: {},
      viewBookingInformation: false,
    };
  },
  async created() {
    this.darkMode = this.$store.state.darkMode;
    // setTimeout(() => {
    //   // this.fixNavBar = this.$store.state.fixNavBar;
    //   this.mini = !this.fixNavBar;
    // }, 1000);

    this.setMode();
    if (!this.currentOrganisation && this.$store.state.currentOrg) {
      this.currentOrganisation = this.$store.state.currentOrg;
      this.organisationKey++;
      this.currentOrganisationKey++;
    } else if (
      !this.currentOrganisation &&
      !this.$store.state.currentOrg &&
      this.$store.state.organisations &&
      this.$store.state.organisations.length > 0
    ) {
      this.organisations = this.$store.state.organisations;
      if (this.organisations.length > 0) {
        let token = this.organisations[0].token;
        await this.$store.dispatch("setOrgToken", token);
        await this.$store.dispatch("setCurrentOrg", this.organisations[0]);
        this.currentOrganisation = this.organisations[0];
        this.currentOrganisationKey++;
        this.organisationKey++;
      }
    }
  },
  computed: {
    ...mapGetters(["tenantBrand"]),

    filterOrganisations() {
      let result = this.organisations
      if(this.currentOrganisation){
        result = this.organisations.filter(
        (x) => x.id != this.currentOrganisation.id
      );
      } 
      if (this.searchOrganisations) {
        result = result.filter(
          (x) =>
            x.name
              .toLowerCase()
              .includes(this.searchOrganisations.toLowerCase()) ||
            (x.alias &&
              x.alias
                .toLowerCase()
                .includes(this.searchOrganisations.toLowerCase()))
        );
      }
      return result;
    },
    week() {
      let week = this.ISOWeek(new Date());
      return week;
    },
  },
  mounted() {
    this.$socket.onopen = () => {
      if (this.$store.state.user) {
        this.$socket.send(
          JSON.stringify({
            action: "join",
            room:
              "user_" +
              this.currentOrganisation.id +
              "_" +
              this.$store.state.user.id,
            user: {
              id: this.$store.state.user.id,
              name:
                this.$store.state.user.firstname +
                " " +
                this.$store.state.user.surname,
              avatar: this.$store.state.user.avatar,
              company: this.$store.state.currentOrg.name,
            },
          })
        );
      }
    };
    this.$socket.onmessage = (data) => {
      let message = JSON.parse(data.data);
      if (this.currentOrganisation && this.currentOrganisation.id) {
        this.handleMessage(message);
      }
    };
  },
  unmounted() {
    delete this.$socket.onmessage;
  },
  watch: {
    search(val) {
      this.initiateGlobalSearch();
    },
    selectedOption() {
      this.initiateGlobalSearch();
    },
    changeOrganisation(value) {
      if (value) {
        this.getUserOrganisations();
        this.drawer = true;
      }
    },
    fixNavBar(value) {
      this.$store.dispatch("setFixNavBar", value);
      this.mini = !this.fixNavBar;
    },
    // mini(val){
    //   let find = this.navbarList.filter(x=>x.active)
    //   find.active=false
    // },
    // drawer(val){
    //   console.log('Drawer update', val)
    //   let find = this.navbarList.filter(x=>x.active)
    //   find.active=false
    // },
    "$store.state.organisations": {
      immediate: true,
      async handler() {
        this.organisationKey++;
      },
    },
    "$store.state.orgToken": {
      immediate: true,
      handler() {},
    },
    "$store.state.authState": {
      immediate: true,
      async handler(value) {
        if (value === "signedin") {
          setTimeout(()=>{
            this.getUserOrganisations();
          }, 200)
        }
      },
    },
    // '$route': {
    //   immediate: true,
    //   handler(val){
    //     console.log('Navbar path', val)
    //   }
    // },
    "$store.state.user": {
      immediate: true,
      async handler(val) {
        this.updateKey++;
        if (!val) {
          console.log(this.$route.path);
          if (
            !this.$route.path.includes("/share/shipment") &&
            !this.$route.path.includes("/register") &&
            !this.$route.path.includes("/resetPassword")
          ) {
            // this.$router.push({ path: '/' })
          }
        } else if (val && val.id) {
          setTimeout(() => {
            if (
              this.$store.state.user &&
              this.currentOrganisation &&
              this.currentOrganisation.id
            ) {
              this.$socket.send(
                JSON.stringify({
                  action: "join",
                  room:
                    "user_" +
                    this.currentOrganisation.id +
                    "_" +
                    this.$store.state.user.id,
                  user: {
                    id: this.$store.state.user.id,
                    name:
                      this.$store.state.user.firstname +
                      " " +
                      this.$store.state.user.surname,
                    avatar: this.$store.state.user.avatar,
                    company: this.$store.state.currentOrg.name,
                  },
                })
              );
            }
          }, 1000);
        }
      },
    },
    async currentOrganisation(value) {
      this.currentOrganisationKey++;
      let currentOrg = this.$store.state.currentOrg;
      this.navbarList = [];

      if (value) {
        // if(localStorage["permissions_" + this.$store.state.currentOrg.id]){
        //   try {
        //     this.navbarList = JSON.parse(localStorage["permissions_" + this.$store.state.currentOrg.id])
        //   } catch(e){
        //     this.navbarList = []
        //   }
        // } else {
        //   this.navbarList = []
        // }
        this.loadingNavigation = true;
        this.$store.dispatch("setCurrentOrg", value);
        this.$store.dispatch("setOrgToken", value.token);
        let newToken = await this.$API.updateUserToken({
          organisation: value.token,
          organisationName: value.name,
        });
        if (newToken) {
          await this.$store.dispatch("setToken", "Bearer " + newToken.token);
        }
        this.sleep(50);
        let routes = await this.$API.getAllOrganisationRoutes();
        let activeRoutes = this.$router.getRoutes();
        let defaultRoutes = activeRoutes.filter(
          (x) => x.meta && x.meta.default
        );
        let addRoutes = routes.filter(
          (x) => !activeRoutes.find((y) => y.path == x.path)
        );
        resetRouter(
          this.$router,
          addRoutes,
          routes.filter((x) => !defaultRoutes.find((y) => y.path == x.path))
        );
        // localStorage.setItem("routes", JSON.stringify(routes))
        let navbarResult = await this.$API.getUserPermissions();
        this.navbarList = navbarResult;
        localStorage.setItem(
          "permissions_" + this.$store.state.currentOrg.id,
          JSON.stringify(this.navbarList)
        );
        this.loadingNavigation = false;
      }
      if (currentOrg && currentOrg.id != value.id) {
        // if (!this.navbarList.find((x) => x.route == this.$route.path)) {
          this.$router.push({ path: "/landing" });
        // }
      }
      if (this.currentOrganisation) {
        setTimeout(() => {
          this.$socket.send(
            JSON.stringify({
              action: "join",
              room:
                "user_" +
                this.currentOrganisation.id +
                "_" +
                this.$store.state.user.id,
              user: {
                id: this.$store.state.user.id,
                name:
                  this.$store.state.user.firstname +
                  " " +
                  this.$store.state.user.surname,
                avatar: this.$store.state.user.avatar,
                company: this.$store.state.currentOrg.name,
              },
            })
          );
        }, 1000);
        // this.getUserNotificationCount();
      }
    },
  },
  methods: {
    bugReport() {
      this.viewBugReport = true;
    },
    async getConsigneeProfileRelationships() {
      if (this.item.id) {
        this.item.consigneeProfileRelationships =
          await this.$API.getConsigneeProfileRelationships(this.item.id);
      }
      this.updateKey++;
    },
    async getConsigneeProfileSubscribers() {
      if (this.item.id) {
        this.item.notificationSubscribers =
          await this.$API.getConsigneeProfileSubscribers(this.item.id);
      }
    },
    async getConsigneeProfileProducts() {
      if (this.item.id) {
        this.item.consigneeProfileProducts =
          await this.$API.getConsigneeProfileProducts(this.item.id);
      }
      this.productKey++;
    },
    async getConsigneeProfiles() {
      this.loading = true;
      this.profiles = await this.$API.listCustomerConsigneeProfile(
        this.selectedCustomer.relatedOrganisationId,
        {}
      );
      this.loading = false;
    },
    addCustomer(item) {
      this.item.consigneeProfileRelationships.push(item);
    },
    addProduct(item) {
      this.item.consigneeProfileProducts.push(item);
    },
    async loadCountries() {
      this.countries = await this.$API.searchCountriesBasic({});
    },
    async getRelatedOrganisations() {
      this.related = await this.$API.getCustomerRelationships(
        this.selectedCustomer.relatedOrganisationId
      );
      this.related.forEach((x) => {
        if (x.relatedOrganisation.primaryOrganisationId) {
          let find = this.related.find(
            (y) =>
              y.relatedOrganisationId ==
              x.relatedOrganisation.primaryOrganisationId
          );
          x.buyer = find.buyer;
          x.producer = find.producer;
          x.shipper = find.shipper;
          x.consignee = find.consignee;
          x.coldstore = find.coldstore;
          x.notifyParty = find.notifyParty;
        }
      });
    },
    async editProfile(item) {
      this.item = await this.$API.getConsigneeProfile(item.id);
      this.item.modalType = "Edit";
      this.item.consigneeProfileShippingContracts =
        await this.$API.getConsigneeProfileContracts(item.id);
      this.item.consigneeProfileDocuments =
        await this.$API.getConsigneeProfileDocuments(item.id);
      this.item.consigneeProfileRelationships =
        await this.$API.getConsigneeProfileRelationships(item.id);
      this.item.consigneeProfileProducts =
        await this.$API.getConsigneeProfileProducts(item.id);
      this.item.notificationSubscribers =
        await this.$API.getConsigneeProfileSubscribers(item.id);
      this.disabledFields = this.selectedCustomer.networkFunctions;
    },
    formatRoute(route) {
      return {
        path: route.path,
        name: route.name,
        meta: {
          protected: route.protected,
          admin: route.admin,
          systemAdmin: route.systemAdmin,
          id: route.id,
        },
        component: () => import(`${route.component}`),
      };
    },
    async getConsigneeProfileData(item) {
      this.itemLoading = true;
      //edit inwerk & selectedCustomer

      //get the current customer
      this.selectedCustomer = await this.$API.getCustomerRelationships(
        item.customerId
      );

      //clear item
      this.item = {};

      //Getting all the relevant data
      await this.editProfile(item);

      this.contacts = await this.$API.getCustomers({});
      await this.loadCountries();
      await this.getRelatedOrganisations();
      await this.getConsigneeProfiles();
      await this.getConsigneeProfileProducts();
      await this.getConsigneeProfileSubscribers();
      await this.getConsigneeProfileRelationships();

      this.itemLoading = false;
      // viewConsigneeProfile = true;
    },
    async getIntermodalData(item) {
      // let bookingContainerData = await this.$API.getBookingContainer(item.ctoNo)
      let bookingData = await this.$API.getBookingDetail(item.bookingReference);
      let bookingContainerData = await this.$API.getBookingContainerData({
        ctoNo: item.ctoNo,
      });
      this.chosenContainer = {
        booking: bookingData,
        container: bookingContainerData[0],
      };
      if (this.chosenContainer) {
        this.viewIntermodal = true;
      }
    },
    async handleMessage(message) {
      if (typeof message == "string") {
        message = JSON.parse(message);
      }
      if (
        this.currentOrganisation &&
        this.currentOrganisation.id &&
        this.$store.state.user &&
        message.room ==
          "user_" +
            this.currentOrganisation.id +
            "_" +
            this.$store.state.user.id &&
        message.type == "new_notification"
      ) {
        this.notifications.total++;
        this.notificationUpdateKey++;
      }
    },
    async saveUserSignatures() {
      this.savingProfileSignatures = true;
      await this.$API.updateUser(this.contact);
      this.$store.state.user = this.contact;
      this.$message.success("Successully updated details!");
      this.savingProfileSignatures = false;
      this.viewProfileSignatures = false;
    },
    editSignature(color) {
      this.signatureColor = color;
      this.signatureDialog = true;
    },
    saveSignature(color) {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (color == "Blue") {
        if (isEmpty) {
          this.contact.signatureBlue = null;
        } else {
          let sig = data.split("base64,")[1];
          this.contact.signatureBlue = sig;
        }
      } else if (color == "Black") {
        if (isEmpty) {
          this.contact.signature = null;
        } else {
          let sig = data.split("base64,")[1];
          this.contact.signature = sig;
        }
      }
      this.$refs.signaturePad.clearSignature();
      this.signatureDialog = false;
    },
    clearSignature() {
      this.$refs.signaturePad.clearSignature();
    },
    closeSignature() {
      this.$refs.signaturePad.clearSignature();
      this.signatureDialog = false;
    },
    editInitials(color) {
      this.initialsColor = color;
      this.initialsDialog = true;
    },
    async editNotificationSettings() {
      this.contact = this.$store.state.user;
      this.availableSettings = await this.$API.getUserNotificationTemplates();
      this.notificationSettings = await this.$API.getUserNotificationSettings();
      this.selectedSettings = this.notificationSettings
        .filter((x) => x.enabled)
        .map((x) => x.value);
      this.notificationSettingsModal = true;
    },
    saveInitials(color) {
      const { isEmpty, data } = this.$refs.initialsPad.saveSignature();
      if (color == "Blue") {
        if (isEmpty) {
          this.contact.initialsBlue = null;
        } else {
          let sig = data.split("base64,")[1];
          this.contact.initialsBlue = sig;
        }
      } else if (color == "Black") {
        if (isEmpty) {
          this.contact.initials = null;
        } else {
          let sig = data.split("base64,")[1];
          this.contact.initials = sig;
        }
      }
      this.$refs.initialsPad.clearSignature();
      this.initialsDialog = false;
    },

    clearInitials() {
      this.$refs.initialsPad.clearSignature();
    },
    closeInitials() {
      this.$refs.initialsPad.clearSignature();
      this.initialsDialog = false;
    },

    clickOutside() {
      setTimeout(() => {
        this.searchOrganisations = "";
        this.changeOrganisation = false;
      }, 200);
    },
    clickChangeOrganisation() {
      this.changeOrganisation = true;
      this.$nextTick(() => {
        this.$refs["organisationSearch"].$refs.input.focus();
      });
    },
    editUser() {
      this.contact = this.$store.state.user;
      this.viewProfile = true;
    },
    firstDayOfWeek() {
      var curr = new Date();
      var first = curr.getDate() - curr.getDay() + 1;
      var firstday = new Date(curr.setDate(first));
      return this.formatDate(firstday);
    },
    lastDayOfWeek() {
      var curr = new Date(); // get current date
      var first = curr.getDate() - curr.getDay();
      var last = first + 7;
      var lastday = new Date(curr.setDate(last));
      return this.formatDate(lastday);
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(date, "yyyy-mm-dd HH:MM");
      }
      return result;
    },
    async getUserOrganisations() {
      this.organisations = await this.$API.getUserOrganisations();
      if (this.organisations.length > 0) {
        await this.$store.dispatch("setOrganisations", this.organisations);
        if (!this.currentOrganisation) {
          let token = this.organisations[0].token;
          await this.$store.dispatch("setOrgToken", token);
          await this.$store.dispatch("setCurrentOrg", this.organisations[0]);
          this.currentOrganisation = this.organisations[0];
          this.organisationKey++;
        }
      }
    },
    async getUserNotificationCount() {
      let count = await this.$API.getNotificationTotals();
      this.notifications.total = count.user;
      this.notificationUpdateKey++;
    },
    initiateGlobalSearch() {
      if (this.search) {
        if (this.globalSearchTimeout) {
          clearTimeout(this.globalSearchTimeout);
        }
        if (this.loadingGlobalSearch && this.globalQuery) {
          this.globalQuery.abort();
        }
        this.loadingGlobalSearch = true;
        this.globalSearchTimeout = setTimeout(async () => {
          this.globalQuery = new AbortController();
          const signal = this.globalQuery.signal;
          this.searchResults = await this.$API.globalSearch({
            params: {
              type: this.options[this.selectedOption],
              search: this.search,
            },
            signal,
          });
          this.loadingGlobalSearch = false;
        }, 750);
      } else {
        this.searchResults = [];
        this.loadingGlobalSearch = false;
      }
    },
    ISOWeek(dt) {
      var tdt = new Date(dt.valueOf());
      var dayn = (dt.getDay() + 6) % 7;
      tdt.setDate(tdt.getDate() - dayn + 3);
      var firstThursday = tdt.valueOf();
      tdt.setMonth(0, 1);
      if (tdt.getDay() !== 4) {
        tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
      }
      return 1 + Math.ceil((firstThursday - tdt) / 604800000);
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
      this.editContact = false;
      this.viewProfile = false;
    },
    async setMode() {
      this.darkMode = true;
      await this.$store.dispatch("setDarkMode", this.darkMode);
      this.$vuetify.theme.dark = this.darkMode;
    },
    async saveUser() {
      this.savingUser = true;
      await this.$API.updateUser(this.contact);
      this.$store.state.user = this.contact;
      this.$message.success("Successully updated details!");
      this.savingUser = false;
      this.editContact = false;
      this.viewProfile = false;
    },
    async signOut() {
      await this.$store.dispatch("setAuthState", "signedout");
      this.$store.dispatch("setToken", null);
      this.$store.dispatch("setUser", null);
      this.$store.dispatch("setTokenExpiry", null);
      this.$store.dispatch("setAuthState", null);
      this.$store.dispatch("setOrganisations", null);
      this.$store.dispatch("setCurrentOrg", null);
      this.$store.dispatch("setOrgToken", null);
      this.$router.push({
        path: "/",
      });
    },
    async startGuide() {
      console.log(this.$route.fullPath);
    },
    isMobile() {
      if (typeof screen.orientation !== "undefined") {
        return false;
      } else {
        return true;
      }
    },
    async saveNotificationSettings() {
      this.savingNotifications = true;
      let newNotifications = this.selectedSettings
        .filter(
          (x) => !this.notificationSettings.map((y) => y.value).includes(x)
        )
        .map((x) => ({ value: x, enabled: true }));
      let updateNotifications = [];
      for (let i = 0; i < this.selectedSettings.length; i++) {
        let find = this.notificationSettings.find(
          (x) => x.value === this.selectedSettings[i]
        );
        if (find) {
          updateNotifications.push({
            ...find,
            enabled: true,
          });
        }
      }
      let remove = this.notificationSettings
        .filter((x) => !this.selectedSettings.includes(x.value))
        .map((x) => ({ ...x, enabled: false }));
      let notifications = [
        ...newNotifications,
        ...updateNotifications,
        ...remove,
      ];
      this.notificationSettings = await this.$API.createUserNotificationSetting(
        { batch: notifications }
      );
      await this.$API.updateUser({
        id: this.$store.state.user.id,
        emailNotification: this.contact.emailNotification,
        pushNotification: this.contact.pushNotification,
        inAppNotification: this.contact.inAppNotification,
      });
      this.$store.state.user = this.contact;

      this.$message.success("Successully updated notification settings!");
      this.notificationSettingsModal = false;
      this.savingNotifications = false;
    },
    sleep(milliseconds) {
      var start = new Date().getTime();
      for (var i = 0; i < 1e7; i++) {
        if (new Date().getTime() - start > milliseconds) {
          break;
        }
      }
    },
    setActive(item, index) {
      item.active = true
      let findOther = this.navbarList.filter((x, i) => i != index)
      for(let i = 0; i < findOther.length; i++){
        findOther[i].active = false
      }
      this.navbarKey ++
      this.activeParent = index;
    },
    setSubActive(item, subitem, index, subindex) {
      this.setActive(item, index)
      this.activeParent = index;
      this.activeChild = subindex;
      subitem.active = true
      // console.log(item, subitem, index, subindex)
      this.navbarList[index].activeList = subindex
      // subitem.active = true
      this.navbarList[index].items.forEach((x, i)=>{
        if(i != subindex){
          x.active = false
        }
      })
      // for(let i = 0; i < findOther.length; i++){
      //   findOther[i].active = false
      // }
      this.$router.push({
        path: subitem.route,
      });
      this.navbarKey++;
    },
    viewBooking(reference) {
      this.globalSearch = false;
      this.$router.push({
        path: "/booking/" + reference,
      });
    },
    async viewContainer(id) {
      this.container = { id };
      this.containerModal = true;
    },
    async viewContainerHistory(item) {
      this.container = { id: item.id, containerNo: item.title };
      this.containerHistoryModal = true;
    },

    viewFile(id) {
      this.selectedFileId = id;
      this.previewKey++;
      this.fileModal = true;
    },
    async viewBookingDetail(reference) {
      this.selectedBooking = await this.$API.getBookingDetail(reference);
      this.viewBookingInformation = true;
    },
    async viewOwnerShipment(reference) {
      this.globalSearch = false;
      this.$router.push({
        path: "/documentation/" + reference,
      });
    },
    async viewShipment(reference) {
      this.globalSearch = false;
      this.$router.push({
        path: "/shipment/" + reference,
      });
    },
  },
};
</script>
<style>
v-list-group__header {
  padding-left: 0;
  margin-left: 0;
}
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
  background-color: rgba(0, 0, 0, 0);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.signature {
  background-color: #ffffff;
}

.signature-container {
  background-color: #ffffff;
  background-image: url("../../assets/signature.png");
  background-size: 100% 100%;
}

canvas {
  height: auto;
  widows: auto;
}

.logo {
  display: inline-block;
  margin: 0 5px;
  font-size: 28px;
  font-family: Cinzel, serif;
}

.smallLogo {
  display: inline-block;
  margin: 0 2px;
  font-size: 26px;
  font-family: Cinzel, serif;
  line-height: normal;
  font-weight: bold;
}

.v-app-bar-title__content {
  width: 200px !important;
}

.count {
  /* position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 14px; */
  animation: move 0.5s;
}

@keyframes move {
  0% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(-10px);
  }

  40% {
    transform: translateY(0);
  }
}
.searchDialog {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
